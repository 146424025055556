import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import useInitClient from 'hooks/useInitClient'
import logger from 'util/logger'
import BookingTable from 'components/Tables/BookingTable'
import DeleteUserConfirmation from 'components/Dialog/DeleteUserConfirmation'
import UserVerificationStatusCard from 'components/UserDetails/UserVerificationStatusCard'
import UserAccountRelationsCard from 'components/UserDetails/UserAccountRelationsCard'
import UserPersonDataCard from 'components/UserDetails/UserPersonDataCard'
import HomeZonesTable from 'components/Tables/HomeZonesTable'
import DTicketsTable from 'components/Tables/DTicketsTable'
import CommentCard from 'components/Tables/CommentCard'

import {
  Box,
  Button,
  Grid,
  Typography,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core'
import { OidcSecure } from '@axa-fr/react-oidc-context'
import { formatSubscriptionGroups } from 'util/dTickets'

const UserDetailsPage = () => {
  const { userId } = useParams()
  const initClient = useInitClient()
  const [user, setUser] = useState(null)
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false)
  const [userHomeZones, setUserHomeZones] = useState(null)
  const [userPaymentAccount, setUserPaymentAccount] = useState(null)
  const [isPaymentAccountLoading, setPaymentAccountLoading] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [comment, setComment] = useState()
  const [dTickets, setDTickets] = useState([])

  const {
    CellPhoneNumber,
    CustomerAccountId,
    Email,
    FirstName,
    IsCellPhoneNumberConfirmed,
    IsEmailConfirmed,
    LastName,
    MobilityProviders,
    State,
    ValidFrom,
    Salutation,
    DateOfBirth,
    Address,
  } = user || {}

  const {
    StateCustomerAccount,
  } = userPaymentAccount || {}

  const fetchUserHomeZones = useCallback(
    () => {
      // check for active Homezone first
      const getActiveHomeZoneId = () => {
        return initClient.customerHomeZones.getCustomerHomeZones({
          customerAccountId: userId,
          active: true,
        })
          .then(response => {
            return response.obj[0]?.HomeZone?.HomeZoneId
          },
          error => {
            logger.error('Error while fetching data', error)
          })
      }
      // fetch all HomeZones and add isActive for further processing
      const getAllHomeZones = (activeHomeZoneId) => {
        return initClient.customerHomeZones.getCustomerHomeZones({
          customerAccountId: userId,
          active: false,
        })
          .then(response => {
            const userHomeZones = response.obj.map((homeZone) => {
              return {
                ...homeZone,
                isActive: homeZone.HomeZone.HomeZoneId === activeHomeZoneId,
              }
            })
            // reversing order to have active at top
            setUserHomeZones(userHomeZones.reverse())
            return response.obj
          },
          error => {
            logger.error('Error while fetching data', error)
          })
      }

      getActiveHomeZoneId()
        .then((getActiveHomeZoneId) => {
          getAllHomeZones(getActiveHomeZoneId)
        })
    },
    [initClient, userId],
  )

  const refundBookingItem = useCallback(
    (mobilityProviderNumber, bookingItemId, comment) => {
      return initClient.mobilityProviders.cancellation(
        { MobilityProviderNumber: mobilityProviderNumber },
        {
          requestBody: {
            BookingItemID: bookingItemId,
            Comment: comment,
          },
        }
      )
    },
    [initClient],
  )

  const refundHomeZone = useCallback(
    (homeZoneId, refundAmount) => {
      return initClient.customerHomeZones.deleteCustomerHomeZones({
        customerAccountId: userId,
        homeZoneId: homeZoneId,
      }, {
        requestBody: {
          RefundAmount: refundAmount,
        },
      })
        .then(() => {
          return fetchUserHomeZones()
        })
        .catch(error => {
          logger.error('Error while fetching data', error)
        })
    },
    [fetchUserHomeZones, initClient, userId],
  )
  // user as dependency ensures that getCustomerDetails is called again if user is set to null on deletion
  useEffect(() => {
    initClient.customerAccounts.getCustomerDetails({ customerAccountId: userId })
      .then(response => {
        setUser(response.obj)
        return response.obj
      },
      error => {
        logger.error('Error while fetching data', error)
      })
  }, [initClient, userId])

  useEffect(() => {
    fetchUserHomeZones()
  }, [fetchUserHomeZones])

  useEffect(() => {
    const fetchExternalBookings = async () => {
      try {
        const response = await initClient.customerAccounts.getExternalBookings({
          customerAccountId: userId,
          ProviderNumber: 2,
        })

        if (response?.obj?.Result) {
          const groups = formatSubscriptionGroups(response?.obj?.Result)
          setDTickets(groups)
        }
      } catch (error) {
        logger.error('Error while fetching data', error)
      }
    }

    fetchExternalBookings()
  }, [initClient, userId])

  const reloadComment = useCallback(() => {
    initClient.customerAccounts.getCommentCustomerAccount({ customerAccountId: userId })
      .then(response => {
        setComment(response.obj.Comment)
        return response.obj
      },
      error => {
        logger.error('Error while fetching data', error)
      })
  }, [initClient.customerAccounts, userId])

  useEffect(() => {
    reloadComment()
  }, [reloadComment])

  useEffect(() => {
    setPaymentAccountLoading(true)
    initClient.customerPaymentAccount.getCustomerPaymentAccount({ customerAccountId: userId })
      .then(response => {
        setUserPaymentAccount(response.obj)
        return response.obj
      },
      error => {
        logger.error('Error while fetching data', error)
      })
      .finally(() => {
        setPaymentAccountLoading(false)
      })
  }, [initClient, userId])

  const isUserDeleted = useMemo(() => {
    return user?.State === 'Closed'
  }, [user])

  useStyles()

  const reloadUser = useCallback(() => {
    initClient.customerAccounts.getCustomerDetails({ customerAccountId: userId })
      .then(response => {
        setUser(response.obj)
        return response.obj
      },
      error => {
        logger.error('Error while fetching data', error)
      })
  }, [initClient.customerAccounts, userId])

  const handleDeleteUserDialog = () => {
    setDeleteUserDialogOpen(true)
  }

  const handleCloseDeleteUserDialog = () => {
    setDeleteUserDialogOpen(false)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const cancelUserAccount = () => {
    initClient.customerAccounts.closeCustomerAccount({ customerAccountId: userId })
      .then(response => {
        setDeleteUserDialogOpen(false)
        setOpenSnackbar(true)
        setUser(null)
      },
      error => {
        logger.error('Error deleting user', error)
      })
  }

  return (
    <OidcSecure>
      {user && (
        <>
          <Box mb={4}>
            <Grid container>
              <Grid item xs={9}>
                <Box clone pr={2}>
                  <Typography noWrap component='span' variant='h4'>{`${FirstName} ${LastName}`}</Typography>
                </Box>
                <Typography noWrap component='span' color='textSecondary' variant='h6'>{`User ID: ${CustomerAccountId}`}</Typography>
              </Grid>
              <Grid container item justify='flex-end' xs={3}>
                <Button color='primary' disabled={isUserDeleted} onClick={() => handleDeleteUserDialog()} variant='outlined'>
                  Nutzer kündigen
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Box mb={1}>
                <Typography component='span' color='textSecondary' variant='h6'>Zugangsdaten</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mb={1}>
                <Typography component='span' color='textSecondary' variant='h6'>Persönliche Daten</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box mb={1}>
                <Typography component='span' color='textSecondary' variant='h6'>Konten</Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <UserVerificationStatusCard
                cellPhoneNumber={CellPhoneNumber}
                email={Email}
                isCellPhoneNumberConfirmed={IsCellPhoneNumberConfirmed}
                isEmailConfirmed={IsEmailConfirmed}
                state={State}
                userId={userId}
                validFrom={ValidFrom}
                onReload={reloadUser}
              />
            </Grid>

            <Grid item xs={4}>
              <UserPersonDataCard
                salutation={Salutation}
                firstName={FirstName}
                lastName={LastName}
                dateOfBirth={DateOfBirth}
                validFrom={ValidFrom}
                address={Address}
              />
            </Grid>
            <Grid item xs={3}>
              <UserAccountRelationsCard
                isLoading={isPaymentAccountLoading}
                mobilityProviders={MobilityProviders}
                paymentAccountState={StateCustomerAccount}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component='span' color='textSecondary' variant='h6'>Support Verlauf</Typography>
            </Grid>
            <Grid item xs={12}>
              <CommentCard
                onReload={reloadComment}
                userId={userId}
                comment={comment}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component='span' color='textSecondary' variant='h6'>Buchungen</Typography>
            </Grid>
            {dTickets?.length > 0 &&
              <Grid item xs={12}>
                <DTicketsTable
                  dTickets={dTickets}
                />
              </Grid>}
            {userHomeZones?.length > 0 &&
              <Grid item xs={12}>
                <HomeZonesTable
                  homeZones={userHomeZones}
                  refundHomeZone={refundHomeZone}
                />
              </Grid>}
            <Grid item xs={12}>
              <BookingTable
                refundBookingItem={refundBookingItem}
              />
            </Grid>
          </Grid>
          <DeleteUserConfirmation
            open={deleteUserDialogOpen}
            onClose={handleCloseDeleteUserDialog}
            userId={userId}
            cancelUserAccount={cancelUserAccount}
          />
          <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
            <SnackbarContent message='Nutzer erfolgreich gekündigt' />
          </Snackbar>
        </>
      )}
    </OidcSecure>
  )
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '.fullHeight': {
      height: '100%',
    },
  },
}))

export default UserDetailsPage
