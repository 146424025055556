import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  Grid,
  Divider,
  makeStyles,
  withStyles,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import { subscriptionStatuses } from 'util/dTickets'

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props
  const classes = useStyles()
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography component='div' variant='h6'>{children}</Typography>
      {onClose &&
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>}
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any,
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    marginRight: theme.spacing(),
    padding: theme.spacing(),
  },
}))(MuiDialogActions)

/**
*  this dialog manages its internal "open" state but has an imperative handle to enable parent components to open it
*  see https://reactjs.org/docs/hooks-reference.html#useimperativehandle
*/
const DTicketStatusInfoDialog = forwardRef((props, ref) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    show: () => {
      setOpen(true)
    },
  }))

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <Dialog onClose={handleClose} aria-labelledby='title' open={open} fullWidth>
      <DialogTitle id='title' onClose={handleClose}>
        <Typography component='div' variant='h4' className={classes.mb2}>Status Details</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid item className={classes.mb2}>
          {subscriptionStatuses.map(status => (
            <>
              <Typography variant='subtitle1' noWrap className={classes.caption}>
                {status.title}
              </Typography>
              <Typography variant='caption' className={classes.caption}>
                {status.description}
              </Typography>
            </>
          ))}
        </Grid>
      </DialogContent>
      <Divider variant='middle' />
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant='contained' color='primary'>
          Schliessen
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const useStyles = makeStyles(theme => ({
  mb2: {
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
  caption: {
    fontWeight: 400,
  },
}))

export default DTicketStatusInfoDialog
