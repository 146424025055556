import React, { forwardRef, useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MaterialTable from 'material-table'
import clsx from 'clsx'
import DTicketStatusInfoDialog from 'components/Dialog/DTicketStatusInfoDialog'

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons'

import {
  Avatar,
  Grid, IconButton, Table, TableBody, TableCell, TableRow,
  Typography,
} from '@material-ui/core'

import { ReactComponent as Logo } from 'assets/dticket.svg'
import { groupPrice } from 'util/booking'
import { formatTimeWithMonth, formatTime } from 'util/dTickets'

/**
 * A component providing a (Material)table of Dtickets
 * @param dTickets
 * @returns {JSX.Element}
 * @constructor
 */

const DTicketsTable = ({
  dTickets,
}) => {
  const classes = useStyles()
  const dialogRef = useRef(null)

  const tableIcons = useMemo(() => {
    return {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    }
  }, [])

  const renderFirstBodyColumn = useCallback((rowData, validFrom) => {
    const caption = 'D-Ticket'

    return (
      <>
        <Grid container alignItems='center' wrap='nowrap'>
          <Grid item style={{ marginLeft: 58 }}>
            <Avatar
              classes={{ root: classes.avatar }}
              style={{
                backgroundColor: '#ffffff',
              }}
            >
              <Logo />
            </Avatar>
          </Grid>
          <Grid item className={clsx(classes.pl05, classes.mr1)}>
            <Typography variant='body1' noWrap>{caption}</Typography>
            {validFrom && <Typography variant='body2' className={classes.caption} noWrap>{formatTimeWithMonth(validFrom)}</Typography>}
          </Grid>
        </Grid>
      </>
    )
  }, [classes.avatar, classes.caption, classes.mr1, classes.pl05])

  const renderFirstColumn = useCallback((rowData, validFrom) => {
    const caption = validFrom ? 'D-Ticket' : 'D-Ticket Abo'
    const tickets = !rowData.items?.length ? '1 Ticket' : `${rowData.items?.length} Tickets`
    return (
      <>
        <Grid container alignItems='center' wrap='nowrap'>
          <Grid item>
            <Avatar
              classes={{ root: classes.avatar }}
              style={{
                backgroundColor: '#ffffff',
              }}
            >
              <Logo />
            </Avatar>
          </Grid>
          <Grid item className={clsx(classes.pl05, classes.mr1)}>
            <Typography variant='body1' noWrap>{caption}</Typography>
            {!validFrom && <Typography variant='body2' className={classes.caption} noWrap>{tickets}</Typography>}
            {validFrom && <Typography variant='body2' className={classes.caption} noWrap>{formatTimeWithMonth(validFrom)}</Typography>}
          </Grid>
        </Grid>
      </>
    )
  }, [classes.avatar, classes.caption, classes.mr1, classes.pl05])

  const renderStatus = useCallback((rowData, validFrom) => {
    const caption = 'Status'
    const info = !rowData.items ? rowData.CurrentSubscriptionStatus : rowData.items[0].CurrentSubscriptionStatus
    const handleOpen = () => {
      if (dialogRef.current) {
        dialogRef.current.show()
      }
    }
    return (
      <Grid container alignItems='center' justifyContent='space-between'>
        <Grid item>
          <>
            <Typography variant='caption' noWrap className={classes.caption}>
              {caption}
            </Typography>
            <Typography variant='body2' noWrap>
              {info || 'nicht verfügbar'}
            </Typography>
          </>
        </Grid>
        <Grid item className={classes.mr2}>
          <IconButton className='icon-information-circle' color='primary' size='small' onClick={handleOpen} />
          <DTicketStatusInfoDialog ref={dialogRef} />
        </Grid>
      </Grid>
    )
  }, [classes.caption, classes.mr2])

  const renderColumn = useCallback((caption, info) => {
    if (!caption && info) {
      return null
    }
    return (
      <>
        <Typography variant='caption' noWrap className={classes.caption}>{caption}</Typography>
        <Typography variant='body2' noWrap>{info || 'nicht verfügbar'}</Typography>
      </>
    )
  }, [classes.caption])

  const renderColumnDTicket = useCallback((rowData, column) => {
    const ticketData = [
      { caption: 'Abo ID', info: !rowData?.items ? rowData?.References?.SubscriptionId : rowData?.items[0]?.References?.SubscriptionId },
      { caption: 'Gültig ab', info: formatTime(rowData?.ValidFrom) },
      { caption: 'Gültig bis', info: formatTime(rowData?.ValidTo) },
      { caption: 'Status', info: !rowData?.items ? rowData?.CurrentSubscriptionStatus : rowData?.items[0]?.CurrentSubscriptionStatus },
      { caption: 'Name', info: rowData?.CustomerName },
    ]
    return renderColumn(ticketData[column].caption, ticketData[column].info)
  }, [renderColumn])

  const renderBodyColumnDTicket = useCallback((rowData, column) => {
    const ticketData = [
      { caption: 'Ticket ID', info: rowData?.References?.TicketId },
      { caption: 'Product Code', info: rowData?.ProductCode },
      { caption: 'Gültig ab', info: formatTime(rowData?.ValidFrom) },
      { caption: 'Gültig bis', info: formatTime(rowData?.ValidTo) },
      { caption: 'Preis', info: Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(groupPrice(rowData)) },
    ]
    return renderColumn(ticketData[column].caption, ticketData[column].info)
  }, [renderColumn])

  const renderTableColumn = useCallback((rowData, column) => {
    return renderColumnDTicket(rowData, column)
  }, [renderColumnDTicket])

  const renderBodyTableColumn = useCallback((rowData, column) => {
    return renderBodyColumnDTicket(rowData, column)
  }, [renderBodyColumnDTicket])

  const renderExtraRowData = useCallback((rowData) => {
    if (rowData?.items?.length > 1) {
      return (
        <Table>
          <TableBody>
            {
              rowData.items.map((bookingItem, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.rowWidth200}>
                      {renderFirstBodyColumn(bookingItem, bookingItem.ValidFrom)}
                    </TableCell>
                    <TableCell className={classes.rowWidth175}>
                      {renderBodyTableColumn(bookingItem, 0)}
                    </TableCell>
                    <TableCell className={classes.rowWidth175}>
                      {renderBodyTableColumn(bookingItem, 1)}
                    </TableCell>
                    <TableCell className={classes.rowWidth175}>
                      {renderBodyTableColumn(bookingItem, 2)}
                    </TableCell>
                    <TableCell className={classes.rowWidth175}>
                      {renderBodyTableColumn(bookingItem, 3)}
                    </TableCell>
                    <TableCell className={classes.rowWidth175}>
                      {renderBodyTableColumn(bookingItem, 4)}
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      )
    } else {
      return (
        <Table>
          <TableBody>
            <TableRow>
              {/* /!* XXX enforce approximately same width as columns in main table *!/ */}
              <TableCell className={classes.rowWidth200}>
                {renderFirstBodyColumn(rowData, rowData.ValidFrom)}
              </TableCell>
              <TableCell className={classes.rowWidth175}>
                {renderBodyTableColumn(rowData, 0)}
              </TableCell>
              <TableCell className={classes.rowWidth175}>
                {renderBodyTableColumn(rowData, 1)}
              </TableCell>
              <TableCell className={classes.rowWidth175}>
                {renderBodyTableColumn(rowData, 2)}
              </TableCell>
              <TableCell className={classes.rowWidth175}>
                {renderBodyTableColumn(rowData, 3)}
              </TableCell>
              <TableCell className={classes.rowWidth175}>
                {renderBodyTableColumn(rowData, 4)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    }
  }, [classes.rowWidth175, classes.rowWidth200, renderBodyTableColumn, renderFirstBodyColumn])

  return (
    <Grid
      container
      className={classes.grid}
      style={{ width: '100%' }}
    >
      <MaterialTable
        icons={tableIcons}
        options={{
          draggable: false,
          emptyRowsWhenPaging: false,
          header: false,
          pageSize: 10,
          pageSizeOptions: [],
          search: false,
          showTitle: false,
          showFirstLastPageButtons: false,
          sorting: false,
          toolbar: false,
          tableLayout: 'auto',
          headerStyle: {
            whiteSpace: 'nowrap',
          },
        }}
        style={{ flex: 1 }}
        columns={[
          {
            field: 'MobilityProviderNumber ',
            render: rowData => renderFirstColumn(rowData),
            cellStyle: { width: '20%' },
          },
          {
            field: 'id',
            render: rowData => renderTableColumn(rowData, 0),
            cellStyle: { width: '20%' },
          },
          {
            field: 'validFrom',
            render: rowData => null,
            cellStyle: { width: '20%' },
          },
          {
            field: 'vilidTo',
            render: null,
            cellStyle: { width: '20%' },
          },
          {
            field: 'status',
            render: rowData => renderStatus(rowData),
            cellStyle: { width: '20%' },
          },
          {
            field: 'name',
            render: rowData => renderTableColumn(rowData, 4),
          },
        ]}
        detailPanel={[
          rowData => ({
            icon: ChevronRight,
            render: renderExtraRowData,
          }),
        ]}
        data={dTickets}
      />
    </Grid>
  )
}

DTicketsTable.propTypes = {
  dTickets: PropTypes.array,
}
const useStyles = makeStyles(theme => ({
  avatar: {
    width: 50,
    height: 50,
    color: 'white',
  },
  caption: {
    color: theme.raumo.palette.typography.textColorMediumEmphasisDark,
    ...theme.typography.caption,
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
  mr2: {
    marginLeft: theme.spacing(2), // Например, 8px * 2 = 16px
  },
  pl05: {
    paddingLeft: theme.spacing(0.5),
  },
  tabs: {
    margin: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  table: {
    width: '100%',
  },
  displayNone: {
    display: 'none',
  },
  rowWidth175: {
    width: 175,
  },
  rowWidth200: {
    width: 200,
  },
}))

export default DTicketsTable
