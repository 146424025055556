const document = {
  openapi: '3.0.1',
  info: {
    title: 'init Zeug',
    contact: {
      email: 'regiomove@raumobil.com',
    },
    version: '1.0.0',
  },
  tags: [
    {
      name: 'customerAccounts',
      description: 'Operations about customerAccounts',
    },
    {
      name: 'customerBookings',
      description: 'Operations about bookings',
    },
    {
      name: 'customerHomeZones',
      description: 'Operations about homezones',
    },
  ],
  servers: [
    {
      url: `${window.location.origin}/proxy/init`,
    },
  ],
  paths: {
    '/BookingItemPayments/{bookingItemPaymentId}/Comment': {
      // http://85.115.11.167:8098/RegioMove/RegioMoveAPI/Help/Api/GET-api-BookingItemPayments-bookingItemPaymentId-Comment
      get: {
        tags: [
          'bookingItemPayments',
        ],
        description: 'comment for bookingItemPayment',
        operationId: 'getBookingItemPaymentComment',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'bookingItemPaymentId',
            schema: {
              type: 'number',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
            content: {
              $ref: '#/components/responses/bookingItemPaymentComment',
            },
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts': {
      get: {
        tags: [
          'customerAccounts',
        ],
        description: 'List of customers.',
        operationId: 'listCustomerAccounts',
        parameters: [
          {
            in: 'query',
            required: false,
            name: 'FirstNamePattern',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'LastNamePattern',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'PhoneNumberPattern',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'EmailPattern',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'take',
            schema: {
              type: 'number',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'skip',
            schema: {
              type: 'number',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
            content: {
              $ref: '#/components/responses/customerList',
            },
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts/{customerAccountId}/ExternalBookings': {
      get: {
        tags: [
          'customerAccounts',
        ],
        description: 'Get external tickets to the customer account',
        operationId: 'getExternalBookings',
        parameters: [
          {
            in: 'path',
            name: 'customerAccountId',
            required: true,
            schema: {
              type: 'string',
            },
            description: 'ID of the RegioMove account',
          },
          {
            in: 'query',
            name: 'ProviderNumber',
            required: false,
            schema: {
              type: 'string',
            },
            description: 'External provider number',
          },
          {
            in: 'query',
            name: 'MobilityProviderNumber',
            required: false,
            schema: {
              type: 'string',
            },
            description: 'Mobility provider number',
          },
          {
            in: 'query',
            name: 'ProviderReference',
            required: false,
            schema: {
              type: 'string',
            },
            description: 'External reference',
          },
          {
            in: 'query',
            name: 'Status',
            required: false,
            schema: {
              type: 'string',
            },
            description: 'All bookings having a certain status',
          },
          {
            in: 'query',
            name: 'CurrentSubscriptionStatus',
            required: false,
            schema: {
              type: 'string',
            },
            description: 'The status of a subscription that takes into account the current date',
          },
        ],
        responses: {
          200: {
            description: 'OK',
            content: {
              'application/json': {
                schema: {
                  type: 'object',
                  properties: {
                    TotalCount: {
                      type: 'integer',
                      description: 'Total number of external bookings',
                    },
                    Result: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          id: {
                            type: 'string',
                            description: 'ID of the external booking',
                          },
                          provider: {
                            type: 'string',
                            description: 'Provider name',
                          },
                          status: {
                            type: 'string',
                            description: 'Booking status',
                          },
                        },
                      },
                      description: 'List of external bookings',
                    },
                  },
                },
              },
            },
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts/{customerAccountId}/Comment': {
      put: {
        tags: [
          'customerAccounts',
        ],
        description: 'Comments on given customer account',
        operationId: 'commentCustomerAccount',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
        ],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/CommentUpdateModel',
              },
            },
          },
          required: true,
        },
        responses: {
          200: {
            description: 'OK',
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
      get: {
        tags: [
          'customerAccounts',
        ],
        description: 'Comments on given customer account',
        operationId: 'getCommentCustomerAccount',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts/{customerAccountId}/Closure': {
      put: {
        tags: [
          'customerAccounts',
        ],
        description: 'Close given customer account',
        operationId: 'closeCustomerAccount',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts/{customerAccountId}': {
      get: {
        tags: [
          'customerAccounts',
        ],
        description: 'List of customers.',
        operationId: 'getCustomerDetails',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
            content: {
              $ref: '#/components/responses/customer',
            },
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/HomeZones/CustomerAccount/{customerAccountId}': {
      get: {
        tags: [
          'customerHomeZones',
        ],
        description: 'List of homeZones.',
        operationId: 'getCustomerHomeZones',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'active',
            schema: {
              type: 'boolean',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
            content: {
              $ref: '#/components/responses/customer',
            },
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/HomeZones/{homeZoneId}/CustomerAccount/{customerAccountId}/Cancellation': {
      post: {
        tags: [
          'customerHomeZones',
        ],
        description: 'Delete a specific HomeZone homeZones.',
        operationId: 'deleteCustomerHomeZones',
        requestBody: {
          content: {
            'application/json': {
              schema: {
                required: [
                  'RefundAmount',
                ],
                type: 'object',
                properties: {
                  RefundAmount: {
                    type: 'number',
                  },
                },
              },
            },
          },
          required: true,
        },
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'path',
            required: true,
            name: 'homeZoneId',
            schema: {
              type: 'string',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts/{customerAccountId}/Bookings': {
      get: {
        tags: [
          'customerBookings',
        ],
        description: 'List of customers.',
        operationId: 'getCustomerBookings',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'sort',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'BookingID',
            schema: {
              type: 'number',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'MobilityProvider',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'BookingItemPaymentType',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'ProductType',
            schema: {
              type: 'string',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'take',
            schema: {
              type: 'number',
            },
          },
          {
            in: 'query',
            required: false,
            name: 'skip',
            schema: {
              type: 'number',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
            content: {
              $ref: '#/components/responses/customer',
            },
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts/{customerAccountId}/PaymentAccount': {
      get: {
        tags: [
          'customerPaymentAccount',
        ],
        description: 'Gets the payment method info at the Payment Provider',
        operationId: 'getCustomerPaymentAccount',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
            content: {
              $ref: '#/components/responses/paymentAccount',
            },
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts/{customerAccountId}/UpdateEmail': {
      put: {
        tags: [
          'customerAccounts',
        ],
        description: 'updates the email address of a customer',
        operationId: 'updateEmail',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
        ],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/UpdateEmailRequestModel',
              },
            },
          },
          required: true,
        },
        responses: {
          200: {
            description: 'OK',
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts/{customerAccountId}/SendEmail': {
      post: {
        tags: [
          'customerAccounts',
        ],
        description: 'Posts the order to send, to the customer, an email',
        operationId: 'sendEmail',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts/{customerAccountId}/UpdateCellPhoneNumber': {
      put: {
        tags: [
          'customerAccounts',
        ],
        description: 'updates the cell phone number of a customer',
        operationId: 'updateCellPhoneNumber',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
        ],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/UpdateCellPhoneNumberRequestModel',
              },
            },
          },
          required: true,
        },
        responses: {
          200: {
            description: 'OK',
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/CustomerAccounts/{customerAccountId}/SendSMS': {
      post: {
        tags: [
          'customerAccounts',
        ],
        description: 'Posts the order to send, to the customer, a SMS with a PIN',
        operationId: 'sendSms',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'customerAccountId',
            schema: {
              type: 'string',
            },
          },
        ],
        responses: {
          200: {
            description: 'OK',
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/MobilityProviders/{MobilityProviderNumber}/Cancellation': {
      post: {
        tags: [
          'mobilityProviders',
        ],
        description: 'Cancels an already finished rental in the Regiomove system (additional reverse generation).',
        operationId: 'cancellation',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'MobilityProviderNumber',
            schema: {
              type: 'string',
            },
          },
        ],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/CancellationRequestModel',
                properties: {
                  BookingItemID: {
                    type: 'number',
                  },
                  Comment: {
                    type: 'string',
                  },
                },
              },
            },
          },
          required: true,
        },
        responses: {
          200: {
            description: 'OK',
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/MobilityProviders': {
      get: {
        tags: [
          'mobilityProviders',
        ],
        description: 'List all mobilityProviders',
        operationId: 'getAll',
        parameters: [],
        responses: {
          200: {
            description: 'OK',
            content: {
              $ref: '#/components/responses/customerList',
            },
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
    '/Users/{userId}/Credentials': {
      put: {
        tags: [
          'users',
        ],
        description: 'change a users password',
        operationId: 'passwordChange',
        parameters: [
          {
            in: 'path',
            required: true,
            name: 'userId',
            schema: {
              type: 'string',
            },
          },
        ],
        requestBody: {
          content: {
            'application/json': {
              schema: {
                $ref: '#/components/schemas/PasswordChangeModel',
              },
            },
          },
          required: true,
        },
        responses: {
          200: {
            description: 'OK',
          },
        },
        security: [
          {
            initAuth: [],
          },
        ],
      },
    },
  },
  components: {
    responses: {
      customerList: {
        description: 'List',
        content: {
          'application/json': {
            schema: {
              type: 'array',
              items: {
                $ref: '#/components/schemas/Customer',
              },
            },
          },
        },
      },
      customer: {
        description: 'User',
        content: {
          'application/json': {
            schema: {
              $ref: '#/components/schemas/Customer',
            },
          },
        },
      },
      bookingList: {
        description: 'List of bookings',
        content: {
          'application/json': {
            schema: {
              type: 'array',
              items: {
                $ref: '#/components/schemas/Booking',
              },
            },
          },
        },
      },
      paymentAccount: {
        description: 'Payment account info and list of payment methods',
        content: {
          'application/json': {
            schema: {
              type: 'object',
              properties: {
                StateCustomerAccount: {
                  type: 'string',
                },
                PaymentsMethods: {
                  type: 'array',
                  items: {
                    $ref: '#/components/schemas/PaymentMethod',
                  },
                },
              },
            },
          },
        },
      },
      mobilityProviderList: {
        description: 'List',
        content: {
          'application/json': {
            schema: {
              type: 'array',
              items: {
                $ref: '#/components/schemas/MobilityProvider',
              },
            },
          },
        },
      },
    },
    schemas: {
      Customer: {
        type: 'object',
        properties: {
          CellPhoneNumber: {
            type: 'string',
          },
          Email: {
            type: 'string',
          },
          FirstName: {
            type: 'string',
          },
          LastName: {
            type: 'string',
          },
          DateOfBirth: {
            type: 'string',
          },
          Gender: {
            $ref: '#/components/schemas/Gender',
          },
          Salutation: {
            $ref: '#/components/schemas/Salutation',
          },
          AcceptTermsAndConditions: {
            type: 'string',
          },
          isNewsLetterAccepted: {
            type: 'boolean',
          },
          Address: {
            $ref: '#/components/schemas/Address',
          },
        },
      },
      Gender: {
        type: 'string',
        enum: [
          'MALE',
          'FEMALE',
          'UNKNOWN',
          'NONBINARY',
        ],
      },
      Salutation: {
        type: 'string',
        enum: [
          'MR',
          'MRS',
          'COMPANY',
          'UNKNOWN',
        ],
      },
      Address: {
        type: 'object',
        properties: {
          AddressLine1: {
            type: 'string',
          },
          AddressLine2: {
            type: 'string',
          },
          PostalCode: {
            type: 'string',
          },
          City: {
            type: 'string',
          },
          Country: {
            type: 'string',
          },
        },
      },
      Booking: {
        type: 'object',
        properties: {
          isBooking: {
            type: 'boolean',
          },
          BikeSharingTrips: {
            type: 'array',
            items: {
              $ref: '#/components/schemas/BikeSharingTrip',
            },
          },
          PublicTransportTrips: {
            type: 'array',
          },
          CarSharingTrips: {
            type: 'array',
          },
        },
      },
      PaymentMethod: {
        type: 'object',
        properties: {
          IsFavorite: 'boolean',
          PaymentMethodId: 'string',
          Label: 'string',
          Description: 'string',
        },
      },
      BikeSharingTrip: {
        type: 'object',
        properties: {
          MobilityProviderNumber: {
            type: 'long',
          },
        },
        BikeType: {
          type: 'integer',
        },
        StartTime: {
          type: 'integer',
        },
        EndTime: {
          type: 'integer',
        },
        StartPlace: {
          type: 'integer',
        },
        Latitude: {
          type: 'string',
        },
        Longitude: {
          type: 'string',
        },
        FeatureReference: {
          type: 'string',
        },
        FeatureName: {
          type: 'string',
        },
        FeatureData: {
          type: 'string',
        },
      },
      CancellationRequestModelHomeZone: {
        required: [
          'RefundAmount',
        ],
        type: 'object',
        properties: {
          RefundAmount: {
            type: 'number',
          },
        },
      },
      CancellationRequestModel: {
        required: [
          'BookingItemID',
          'Comment',
        ],
        type: 'object',
        properties: {
          Amount: {
            type: 'number',
          },
          BookingItemID: {
            type: 'number',
          },
          Comment: {
            type: 'string',
          },
        },
      },
      UpdateEmailRequestModel: {
        required: [
          'EmailAddress',
        ],
        type: 'object',
        properties: {
          EmailAddress: {
            type: 'string',
          },
        },
      },
      // XXX yes, that is not a typo (at least not on our side)!!!
      UpdateCellPhoneNumberRequestModel: {
        required: [
          'CellPoneNumber',
        ],
        type: 'object',
        properties: {
          CellPoneNumber: {
            type: 'string',
          },
        },
      },
      CommentUpdateModel: {
        required: [
          'Comment',
        ],
        type: 'object',
        properties: {
          Comment: {
            type: 'string',
          },
        },
      },
      // http://85.115.11.167:8098/RegioMove/RegioMoveAPI/Help/ResourceModel?modelName=BookingItemPaymentComment
      BookingItemPaymentComment: {
        type: 'object',
        properties: {
          BookingItemPaymentId: {
            type: 'number',
          },
          Comment: {
            type: 'string',
          },
        },
      },
      MobilityProvider: {
        type: 'object',
        properties: {
          OperatorId: {
            type: 'number',
          },
          MobilityProviderNumber: {
            type: 'number',
          },
          DrivingLicenceRequired: {
            type: 'boolean',
          },
          FixedPriceProvider: {
            type: 'boolean',
          },
          MobilityProviderName: {
            type: 'string',
          },
        },
      },
      PasswordChangeModel: {
        required: [
          'OldPassword',
          'NewPassword',
        ],
        type: 'object',
        properties: {
          NewPassword: {
            type: 'string',
          },
          OldPassword: {
            type: 'string',
          },
        },
      },
    },
    securitySchemes: {
      initAuth: {
        type: 'oauth2',
      },
    },

  },
}

export default document
