// Groups an array of objects by `SubscriptionId` and transforms the data structure.
export const formatSubscriptionGroups = (groups) => {
  const originalArray = groups || []

  const groupArray = Object.values(originalArray?.reduce((acc, item) => {
    const subscriptionId = item?.References?.SubscriptionId
    if (subscriptionId != null) {
      if (!acc[subscriptionId]) {
        acc[subscriptionId] = item
      } else if (Array.isArray(acc[subscriptionId])) {
        acc[subscriptionId].push(item)
      } else {
        acc[subscriptionId] = [acc[subscriptionId], item]
      }
    }
    return acc
  }, {}))?.map(group => {
    if (Array.isArray(group)) {
      const activeItems = group.filter(item => item.CurrentSubscriptionStatus === 'Active')
      if (activeItems.length > 0) {
        return { ...activeItems[0], items: group.sort((a, b) => new Date(b.ValidFrom) - new Date(a.ValidFrom)) }
      }
      return { ...group[0], items: group.sort((a, b) => new Date(b.ValidFrom) - new Date(a.ValidFrom)) }
    }
    return group
  })

  return groupArray.sort((a, b) => {
    if (a.CurrentSubscriptionStatus === 'Active' && b.CurrentSubscriptionStatus !== 'Active') {
      return -1
    }
    if (a.CurrentSubscriptionStatus !== 'Active' && b.CurrentSubscriptionStatus === 'Active') {
      return 1
    }
    return 0
  })
}

// dTicket status description
export const subscriptionStatuses = [
  {
    title: 'Incomplete',
    description: 'Die Abonnementerstellung wurde abgeschlossen, aber Daten fehlen.',
  },
  {
    title: 'NotYetAccepted',
    description: 'Das Abonnement wurde erstellt, muss jedoch noch akzeptiert werden (siehe Versions-Endpunkt). Dies gilt nur für Abonnements, die akzeptiert werden müssen.',
  },
  {
    title: 'NotYetActive',
    description: 'Das Abonnement wird in der Zukunft aktiv.',
  },
  {
    title: 'Active',
    description: 'Das Abonnement ist derzeit aktiv.',
  },
  {
    title: 'ActiveAlreadyCancelled',
    description: 'Das Abonnement ist aktiv, wurde jedoch bereits gekündigt und läuft aus.',
  },
  {
    title: 'Cancelled',
    description: 'Das Abonnement wurde gekündigt und ist nicht mehr aktiv.',
  },
  {
    title: 'Expired',
    description: 'Das Abonnement ist abgelaufen und nicht mehr aktiv.',
  },
  {
    title: 'Aborted',
    description: 'Das Abonnement wurde vor dem Aktivwerden abgebrochen.',
  },
]

export const formatTime = timeString => Intl.DateTimeFormat('de-DE', {
  year: 'numeric',
  day: '2-digit',
  month: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
}).format(new Date(timeString))

export const formatTimeWithMonth = timeString => Intl.DateTimeFormat('de-DE', {
  year: 'numeric',
  month: 'long',
}).format(new Date(timeString))
